.vehicle-table-container {
  padding: 10px;
  border-radius: 5px;
  background-color: var(--white);
  margin-top: 10px;
  width: 100%;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
}

.submitted {
  color: green; /* or any color you prefer */
  font-weight: bold;
}

.save-button {
  background-color: #5f71c3 !important;
  color: white !important;
  padding: 13px 18px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.urlContainer{
  margin-top: 5px;
}
.box {
  padding: 2px;
  max-width: 200px;
}

.links {
  display: flex;
  // justify-content: space-around;
  // align-items: center; 
}


.dvvItems{
  border: 1px solid  black;
}

.save-button:hover {
  background-color: #6477cc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.table-header-wrapper {
  background-color: #f1f4f9;
}

.table-header-wrapper {
  color: var(--table-header-black);
  background-color: var(--table-header-back);
  border-radius: 120px !important;
  padding: 12px;
}

.table-header {
  font-size: 1rem;
  font-weight: 600;
  background-color: #f1f4f9 !important;
  border-bottom: 0px;
}

.table-body {
  color: var(--sidebar-text-gray);
}

.table-row {
  font-size: 0.805rem;
  color: var(--original-price);
}

.pagination-action-wrapper {
  border-radius: 40px;
  background: var(--primary);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center; /* Center the pagination actions horizontally */
  margin-top: 20px; /* Space between table and pagination */
}

.pagination-action-wrapper button {
  background: transparent;
  border: none;
  color: #c4b5b5;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 16px; /* Add some padding to buttons for better click area */
  border-radius: 20px; /* Rounded corners for buttons */
  transition:
    background 0.3s,
    color 0.3s; /* Smooth transition for background and color */
}

.pagination-action-wrapper button:hover {
  background: #c4b5b5; /* Change background on hover */
  color: #fff; /* Change text color on hover */
}

.pagination-action-wrapper button:disabled {
  color: #c7c7c7;
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.pagination-action-wrapper p {
  color: #998989;
  font-size: 14px;
  margin: 0; /* Remove default margin from p element */
}

.car-image-box {
  display: flex;
  background-size: contain;
  justify-content: center;
  background: #f5f5f5;
  position: relative;
}

.car-image {
  width: 84px;
  height: 80px;
  cursor: pointer;
  background-size: 100% 100%;
  // position: relative;
  object-fit: contain;
}

.action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.add-icons {
  width: 16px;
}

.delete-icons {
  width: 23px;
}

.mp-logo {
  width: 25px;
  height: 20px;
  margin-left: 10px;
}
.w-logo {
  border-radius: 1px;
  width: 25px;
  height: 23px;
  padding-left: 5px;
  background-color: #989595;
}

.pnlbutton {
  width: 150px;
  background: #121b5b;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  font-size: 13px;
}

.edit-icons {
  width: 17px;
  padding-top: 5px;
}

.toggle-button {
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  background-color: var(--primary);
  color: blue;
  cursor: pointer;
}

.greyish-text {
  color: grey;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-icon-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 0 16px;
  // position: absolute;
  // bottom: 0; /* Position it at the bottom of the image */
  // background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  width: 100%;
  cursor: pointer;
  // border-radius: 20px; /* Rounded corners for the container */
}

.flex-col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.flex-between {
  display: flex;
  gap: 0px;
}

.flex-gap {
  display: flex;
  gap: 10px;
}

.filterDropDown {
  width: 150px;
}

.status-icon {
  padding-top: 10px;
  width: 20px;
  cursor: pointer;
}

.input-checkbox {
  transform: scale(1.3);
}

table td,
table th {
  padding: 1rem 0.5rem 0.5rem 0.5rem !important;
}
.select-wrapper {
  width: 100%;
  margin-bottom: 20px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
}

.multi-slect {
  width: 100%;
}

.select-wrapper .select__control {
  width: 500px;
  padding: 10px;
}

.select-wrapper .select__menu {
  max-height: 200px;
  overflow-y: auto;
}

.select-wrapper .select__option {
  color: #333;
  padding: 10px;
  &:hover {
    background-color: #f0f0f0;
  }
}

.sold-button-action {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-formater {
  padding: 7px;
  border-radius: 4px;
  border: 1px solid rgb(221, 219, 219);
}

.star {
  color: red;
  font-size: 13px;
}

.processButton {
  background-color: #121b5b;
  border-radius: 5px;
  color: white;
  border: none;
  padding: 8px;
}

.action-icon {
  margin-left: 5px;
}


.headingView {
  font-size: 15px;
  // font-weight: bold;
  margin-bottom: 4px;
  // text-align: center;
}