.container-first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  padding: 0px;
}

/* Inner container for the form and image */
.container-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100vw;
  padding: 0px 20px;
  border-radius: 8px;
}

/* Container for the image */
.first-container {
  margin-bottom: 30px;
}

/* Form styles */
.text_input {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.logo-png {
  margin-bottom: 30px;
  text-align: center;
}

.text_area {
  display: flex;
  flex-direction: column;
}

.hadle-site {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.heding-sign-in {
  font-weight: bold;
  color: rgb(53, 115, 230);
  margin-bottom: 25px;
  text-align: center;
  font-size: 24px;
}

.para-one {
  margin-bottom: 20px;
  text-align: center;
  font-size: 10px;
}

.label-second {
  margin-bottom: 10px;
  font-weight: bold;
}

.span-class {
  color: red;
}

.btn {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  color: white;
  background-color: rgb(112, 112, 230);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: rgb(124, 124, 235);
}

@media (max-width: 968px) {
  .container-second {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    padding: 40px 20px;
    border-radius: 8px;
  }

  .text_input,
  .btn {
    font-size: 16px;
    padding: 8px;
  }

  .heding-sign-in {
    font-size: 20px;
  }
}
