.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.vehicle-details-container{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.first-container{
  display: flex;
  justify-content: space-between;
}

.left-side-current, .right-side-current{
  width: 45%;
}

.left-side-current{
  margin-left: 40px;
}
.right-side-current{
  margin-right: 40px;
}

.select-desin{
  width: 400px;
  display: flex;
  flex-direction: column;
  // border: 1px solid black;
}

.wrapper{
  background-color: white;
  margin-top: 20px;
}

.kms-container{
  // border: 1px solid black; 
  display: flex;
  gap: 3px;

}
.input-wrapper{
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  align-items: center;
}
.textarea{
  width: 400px;
  height: 200px;
  border-radius: 4px;
  min-height: 100px;
  padding: 10px;
}

// .tages-current{
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-left: 40px;
//   margin-right: 40px;
// }

.tag-first-container{
  display: flex;
  flex-direction: column;
}


.tages-first-current{
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  margin-top: 10px;
}

.tag-second-current{
  // border: 1px solid black;
  width: 17%;
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  margin-top: 10px;

}

// .boxex{
//   margin-left: 80px;
// }
.primary-button-container-style {
  background-color: var(--primary-blue);
  padding: 10px 40px;
  margin-right: 40px;
  margin-bottom: 40px; 
}
.action-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column; 
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 800px) {
  .wrapper {
    width: 100%;
  }
  .first-container {
    flex-direction: column;
  }
  .left-side-current,
  .right-side-current {
    width: 100%; 
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .input-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px; 
  }

  .select-desin {
    width: 100%; 
    margin-bottom: 10px; 
  }

  .select-design{
    width: 300px !important;
    margin-bottom: 10px;
  }

  .tages-current{
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}

