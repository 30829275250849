.container {
  display: flex;
  gap: 10px;
  align-items: center;

  .label {
    font-size: 14px;
    font-weight: 500;
    min-width: 200px;
    width: 200px;
    span {
      color: red;
      margin: 0 5px;
    }
  }

  .input-field-container {
    min-width: 200px;
    flex: 1;
  }

  .input-field {
    width: 100%;
    border: 1px solid #ebebeb;
    display: flex;
    border-radius: 5px;
    background: #ffffff;
    gap: 10px;
    align-items: center;
    padding-right: 10px;
    &:has(input:disabled) {
      background: #c7c7c7;
    }
    &:has(select:disabled) {
      background: #c7c7c7;
    }
    &.no-border {
      border: none;
    }

    &.error {
      border-color: red;
    }

    input::placeholder {
      color: #918f8f;
    }

    input,
    select,
    textarea {
      border: none;
      background: transparent;
      font-size: 14px;
      padding: 11.5px;
      flex: 1;
      color: #000000;
      option {
        color: #000000;
      }
    }

    .search-select-dropdown-wrapper {
      position: absolute;
      background: #ffffff;
      z-index: 99999;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #efefef;
      box-shadow: 0 0 20px -5px #dfdfdf;
      margin-top: 5px;
      top: 100%;
      overflow-y: auto;

      .default-dropdown-option {
        font-size: 14px;
        padding: 7px 15px;
        border-bottom: 1px solid #efefef;
        cursor: pointer;

        &:hover {
          background: #efefef;
        }
      }
      .custom-dropdown-option {
        &:hover {
          background: #efefef;
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    textarea {
      resize: none;
      height: 100px;
    }

    &.decreased-width {
      input,
      select,
      textarea {
        font-size: 10px;
        // font-size: 14px;
      }
    }
  }

  .trailing-icon {
    width: 18px;
    aspect-ratio: 1/1;
  }

  .error-text {
    color: red;
    font-size: 14px;
  }
}

.container.decreased-width {
  align-items: flex-start;
}
.password-icon {
  position: absolute;
  top: 120px;
  right: 10px;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 5px;
}

.selected-tag {
  background-color: #e0f7fa; /* Light cyan background */
  color: #00796b; /* Dark cyan text */
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
}

.remove-tag-button {
  background: none;
  border: none;
  color: #00796b;
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
}

.remove-tag-button:hover {
  color: #004d40;
}

.input-field select {
  padding: 8px;
  border-radius: 4px;
  color: #333;
  width: 100%;
}

.input-field select[multiple] {
  height: auto;
}

.search-select-dropdown-wrapper {
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.default-dropdown-option {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  color: #333;
}

.default-dropdown-option:hover {
  background-color: #e0f7fa; /* Light cyan on hover */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

@media screen and (max-width: 768px) {
  .container {
    flex-wrap: wrap;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container {
    flex-direction: row !important;
  }
}
