.container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
}

.logo-wrapper {
  width: 200px;
  margin: 0 50px;

  @media screen and (max-width: 768px) {
    width: 150px;
    margin: 0 20px;
  }
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.right-section-container {
  display: flex;
  margin-right: 50px;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-right: 20px;
  }
}

.notification-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 35px;

  @media screen and (max-width: 768px) {
    margin-right: 20px;
  }
}

.bell-icon {
}

.notification-count {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--notification-back-red);
  color: var(--white);
  font-size: 0.8rem;
  text-align: center;
  top: -8px;
  right: -4px;
}

.profile-wrapper {
  display: flex;
  align-items: center;
}

.profile-img {
  width: 40px;
  height: 40px;
  background-color: var(--profile-back-gray);
  border-radius: 50%;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.name {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-dark-gray);
}

.role {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--text-light-gray);
}
