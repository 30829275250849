.container {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}
.filters-wrapper {
  display: flex;
  column-gap: 8px;
}

.table-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  flex: 1 1 0;
  min-height: min-content;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  .table-action-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    // align-items: flex-start;

    .input-field {
      background: #fafafa;
      border-radius: 5px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      min-width: 0;
      flex: 1;
      select {
        width: 90%;
      }
    }
    input,
    select,
    button {
      font-size: 14px;
      background: #fafafa;
      border-radius: 5px;
      border: none;
      padding: 10px 20px;
    }
    input,
    select {
      flex: 1;
    }

    .multi-select {
      flex: 1;
      outline: none !important;
      background: transparent;
      border: none;
    }
    input {
      border-radius: 0;
      padding: 10px 5px;
      background: transparent;
    }
    button {
      font-weight: bold;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .primary-action-button {
      background-color: var(--primary);
      color: #ffffff;
    }
  }

  table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    tr {
      flex: 1;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      padding: 7px 0 !important;
      &:hover {
        background: #efefef;
      }
      td,
      th {
        border: none;
        background: #ffffff !important;
      }
    }
    thead tr:hover {
      background: #ffffff;
    }
  }

  thead {
    display: flex;
    flex-direction: column;

    tr {
      padding: 5px;
      display: flex;
      flex: 1;
    }
    th {
      display: flex;
      align-items: flex-start;
      padding: 5px 19px;
      font-size: 14px;
    }
    th {
      width: 150px;
      flex: 1;
      text-align: center;
    }
    th:last-child {
      text-align: start;
    }
  }
  tbody {
    display: flex;
    flex-direction: column;
    min-height: min-content;

    tr {
      padding: 5px;
      display: flex;
    }
    td {
      padding: 5px 19px;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    td {
      width: 150px;
      flex: 1;
    }
    .status {
      padding: 2px 10px;
      border-radius: 20px;
    }
    .action-container {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 20px;
        aspect-ratio: 1/1;
        cursor: pointer;
      }
    }
  }
}

.table-pagination-container {
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .items-count {
    border-radius: 40px;
    background: #d9d9d9;
    padding: 5px 10px;
    font-size: 14px;
  }

  .pagination-action-wrapper {
    border-radius: 40px;
    background: var(--primary);
    padding: 5px 20px;
    display: flex;
    align-items: center;
    gap: 25px;
    button {
      background: transparent;
      border: none;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;

      &:disabled {
        color: #c7c7c7;
      }
    }
    p {
      color: #585858;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .table-container {
    margin-top: 0;
    width: 100%;
    table {
      display: table-cell;
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .table-container {
    margin-top: 0;
    width: 920px;
    table {
      display: table-cell;
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .table-container {
    margin-top: 0;
    width: 720px;
    table {
      display: table-cell;
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 768px) {
  .table-action-container {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px !important;
    button {
      margin-bottom: 10px;
      justify-content: center;
    }
  }
  .table-container {
    margin-top: 0;
    width: 620px;
    table {
      display: table-cell;
      margin-top: 25px;
    }
  }

  .table-container tbody td {
    display: inline-block;
  }
}

@media screen and (max-width: 576px) {
  .table-pagination-container .items-count {
    font-size: 14px;
  }
  .table-pagination-container .pagination-action-wrapper {
    gap: 10px;
    font-size: 10px;
  }
  .table-container {
    margin-top: 0;
    width: 320px;
    table {
      display: table-cell;
      margin-top: 25px;
    }
  }
  .table-pagination-container {
    padding: 15px 0 0 0;
    display: flex;
    justify-content: space-between;

    .items-count {
      border-radius: 40px;
      background: #d9d9d9;
      padding: 5px 10px;
      font-size: 14px;
    }

    .pagination-action-wrapper {
      border-radius: 40px;
      background: var(--primary);
      padding: 5px 20px;
      display: flex;
      align-items: center;
      gap: 25px;
      button {
        background: transparent;
        border: none;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;

        &:disabled {
          color: #c7c7c7;
        }
      }
      p {
        color: #ffffff;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .table-pagination-container .pagination-action-wrapper {
    gap: 10px;
    padding: 5px 12px;
    p {
      font-size: 12px;
    }
  }
}
.action-buttons-wrapper {
  display: flex;
  column-gap: 12px;
  background-color: var(--primary-blue);
  border-radius: 10px;
  color: white !important;
}

.add-blogs-button {
  color: white !important;
  font-weight: bolder;
  width: 100%;
  border-radius: 2px !important;
  padding: 10px;
}

.excel-button-container-style {
  padding: 6px 14px;
  background-color: var(--excel-green);
}
