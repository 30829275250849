.container {
    display: flex;

    .slider-base {
        border-radius: 40px;
        height: 20px;
        width: 50px;
        padding: 3px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }
    .switch {
        height: 100%;
        aspect-ratio: 1/1;
        background: #FFFFFF;
        border-radius: 100%;
    }
}
