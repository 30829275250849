.container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    // Material-UI font family
  }
  
  .filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-top: 10px;
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 768px) {
      display: grid;
    }
  }
  
  .filters-wrapper {
    display: flex;
    column-gap: 8px;
  
    @media screen and (max-width: 768px) {
      display: grid;
      gap: 8px;
    }
  }
  
  .search-box {
    padding: 5px;
  }
  
  .selectable-button-container-style {
    padding: 1px;
    width: 150px;
  }
  
  .action-buttons-wrapper {
    display: flex;
    column-gap: 12px;
    background-color: var(--primary-blue);
    border-radius: 5px;
    color: white !important;
  }
  .add-blogs-button {
    color: white !important;
    font-weight: bolder;
    width: 100%;
    border-radius: 2px !important;
    padding: 10px;
  }
  
  .excel-button-container-style {
    padding: 6px 14px;
    background-color: var(--excel-green);
  }
  .add-new-car-button-container-style {
    padding: 6px 14px;
    background-color: var(--primary-blue);
  }
  
  .pagination-action-wrapper {
    border-radius: 40px;
    background: var(--primary);
    padding: 5px 20px;
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: center; /* Center the pagination actions horizontally */
    margin-top: 20px; /* Space between table and pagination */
  }
  
  .pagination-action-wrapper button {
    background: transparent;
    border: none;
    color: #c4b5b5;
    font-weight: bold;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 20px;
    transition: background 0.3s, color 0.3s;
  }
  
  .pagination-action-wrapper button:hover {
    background: #c4b5b5;
    color: #fff;
  }
  
  .pagination-action-wrapper button:disabled {
    color: #c7c7c7;
    cursor: not-allowed;
  }
  
  .pagination-action-wrapper p {
    color: #998989;
    font-size: 14px;
    margin: 0;
  }
  
  .perfomace-button{
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .para{
    border: 1px solid gray;
    width: 150px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

  .para p{
    font-size: 18px;
  }