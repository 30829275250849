.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: "#F5F5F5";
  padding: 40px;
  border-radius: 5px;
}

.line {
  border: 1px solid #ebebeb;
  margin: 40px 0;
}

.header-wrapper {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.Heading-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.reset-field {
  align-items: center;
  display: grid;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 40px;
  justify-content: space-between;

  .input-wrapper {
    flex: 1;
    .radio-input-container {
      display: flex;
    }

    .radio-wrapper-text {
      font-weight: 500;
      font-size: 14px;
      min-width: 210px;
      display: inline-block;
      margin-right: 5px;
    }
    .radio-wrapper-text::after {
      content: "*";
      color: red;
      margin-left: 5px;
    }
    .radio-input {
      display: flex;
      column-gap: 35px;
      min-width: 300px;
      .radio-sub-input {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p {
          font-size: 14px;
        }
      }
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
    .file-input-wrapper {
      float: left;
    }
    .image,
    .uploaded-image {
      width: 80px;
      border: 2px dashed #e0e5f2;
      background: #fafcfe;
      border-radius: 5px;
      padding: 10px 12px;
      margin-right: 40px;
    }
    .uploaded-image {
      margin: 0;
    }

    .uploaded-file-wrapper {
      position: relative;

      .image-delete-icon {
        width: 24px;
        aspect-ratio: 1/1;
        position: absolute;
        top: -10px;
        right: -10px;
        background: #000000;
        border-radius: 100%;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
    .checkbox-input-container {
      display: flex;
    }
    .checkbox-wrapper-text {
      font-weight: 500;
      font-size: 14px;
      min-width: 210px;
      display: inline-block;
      margin-right: 5px;
    }
    .checkbox-input {
      display: flex;
      column-gap: 35px;
      min-width: 300px;
      .checkbox-sub-input {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.label {
  font-size: 14px;
  font-weight: bold;
}

.action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 0 0;

  a {
    color: var(--primary);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    .input-wrapper {
      .radio-input-container {
        display: inline-block;
        .radio-wrapper-text {
          margin-bottom: 10px;
        }
      }
      .radio-input {
        //  margin-top: 20px;
        display: flex;
        column-gap: 15px;
        flex-wrap: wrap;
        row-gap: 20px;
      }
    }
  }
}

@media only screen and (max-device-width: 375px) {
  .container {
    padding: 20px !important;
  }
}

@media only screen and (max-device-width: 320px) {
  .wrapper {
    .input-wrapper {
      .radio-input {
        display: flex;
        column-gap: 15px;
        flex-wrap: wrap;
        row-gap: 20px;
        flex-direction: column;
      }
    }
  }
}

.reset-field {
  align-items: center;
  justify-content: space-between;
}

.dropdown-multiple {
  width: 328px;
}
.additional-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-wrapper {
  width: 100%;
}
.vichal-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputfield {
  width: 100%;
}

.autocomplete-wrapper {
  width: auto;
  display: flex;
  gap: 10px;
  margin-bottom: "100px";
  justify-content: space-between;
  align-items: center;
}

.autocomplete {
  width: 100%;
}

.additional-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.reset-button {
  border: none;
  background-color: #007abe;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 10px;
  font-weight: 600;
  padding: 10px;
  width: 110px;
}

.reset-buttonnew {
  border: none;
  background-color: #007abe;
  color: white;
  border-radius: 5px;
  font-size: 10px;
  margin-left: 10px;
  font-weight: 600;
  padding: 1px;
  width: 80px;
}

.button-wrapper {
  margin-top: 10px;
  gap: 5px;
  display: flex;
  margin-left: 1px;
  float: right;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .autocomplete-wrapper {
    width: auto;
    display: grid;
    margin-bottom: "10px";
    justify-content: space-between;
    align-items: center;
  }
  .button-wrapper {
    margin-top: 10px;
    gap: 5px;
    display: flex;
    margin-left: 1px;
    float: right;
  }
}
